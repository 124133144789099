import AxiosApiInstance, {APIBase} from "../AxiosApiInstance";
import {AxiosResponse} from "axios";
import ApiAnswerPostModel, {ApiAnswerGetModel} from "../applications/ApiAnswerModel";

export type ApiAnswersCreateMultiplePostRequest = ApiAnswerPostModel[];

export type ApiAnswersCreateMultiplePostResponse = ApiAnswerGetModel[];

export const ApiAnswersCreateMultiplePost: (answers: ApiAnswersCreateMultiplePostRequest) => Promise<AxiosResponse<ApiAnswersCreateMultiplePostResponse>> = (answers) => {
  const filteredAnswers = answers.filter(answer => !answer.question.endsWith("-same"));
  return AxiosApiInstance.post(APIBase + "answers/create_multiple/", filteredAnswers);
};