/* eslint-disable react-hooks/rules-of-hooks,react-hooks/exhaustive-deps */

import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Formik, FormikProps, useFormikContext} from "formik";
import {Alert, Form} from "react-bootstrap";
import SkFieldText from "./questionTypes/SkFieldText";
import SkFieldPicker from "./questionTypes/SkFieldPicker";
import SkFieldDate from "./questionTypes/SkFieldDate";
import SkFieldPhone from "./questionTypes/SkFieldPhone";
import SkFieldFile from "./questionTypes/SkFieldFile";
import {ApiQuestionRenderedModel} from "../../services/api/questions/ApiQuestionModel";
import ValidationSchemaFromQuestions, {MandatoryMode} from "../../helpers/factory/ValidationSchemaFromQuestions";
import {FormikHelpers} from "formik/dist/types";
import SkSubmitButton from "./SkSubmitButton";
import SkSubmitButtonProps from "./SkSubmitButtonProps";
import SkFieldAutosuggest from "./questionTypes/SkFieldAutosuggest";
import {s2positivei} from "../../helpers/utils";
import SkFieldOutOf from "./questionTypes/SkFieldOutOf";
import SkFieldNumber from "./questionTypes/SkFieldNumber";
import SkFieldRecommendation from "./questionTypes/SkFieldRecommendation";
import {SkFormPropsChecker} from "./SkFormContexts";
import SkFieldProps from "./questionTypes/SkFieldProps";
import { useGraduationStepField } from "../../stores/GraduationStepFieldStore";
import { useFilteredQuestions } from "../../stores/FilteredQuestionsStore";

const REACT_APP_SELECT_AUTOSUGGEST_FROM_ITEMS_GTE = s2positivei(process.env.REACT_APP_SELECT_AUTOSUGGEST_FROM_ITEMS_GTE, 5);

export const isSkFieldValueFilled = (value:SkFormValue) => Array.isArray(value) ? value.length > 0 : !!value;

export const isSkFieldValuesEqual = (value1:SkFormValue, value2:SkFormValue) => {
  if (Array.isArray(value1)) {
    if (Array.isArray(value2)) {
      return value1.join(",") === value2.join(",");
    } else {
      return false;
    }
  } else {
    if (!Array.isArray(value2)) {
      return value1 === value2;
    } else {
      return false;
    }
  }
};

export type SkFormValue = string | string[];

export interface SkFormQuestionsBlock {
  header: React.ReactNode | string;
  questionsIds: string[];
}

export interface SkFormValues {
  [fieldName: string]: SkFormValue;
}

export interface SkFieldSelectValues {
  [key: string]: string;
}

export interface SkFieldSelectDescriptions {
  [key: string]: string | undefined;
}

// если не передать values, то зависимости просто не будут учтены
export const SkSelectValuesFromApiVariants: (question: ApiQuestionRenderedModel, values?: SkFormValues) => SkFieldSelectValues = (question, values) => {
  const resultValues: SkFieldSelectValues = {};

  if (!values) {
    question.variants.sort((a, b) => a.sort - b.sort).forEach(variant => {
      resultValues[variant.id] = variant.variant_value;
    });
  } else {
    // TODO: переписать
    // question.variants.sort((a, b) => a.sort - b.sort).forEach(variant => {
      // if (question.name === 'Educational program' || question.name === 'Program Title' || question.name === 'Отношение к воинской обязанности') {
      //   if ((question.variants_depend_on && values[question.variants_depend_on] === variant.depends_on) || !question.variants_depend_on) {
      //     resultValues[variant.id] = variant.variant_value;
      //   }
      // } else {
      //     resultValues[variant.id] = variant.variant_value;
      // }
    // });
    question.variants.sort((a, b) => a.sort - b.sort).forEach(variant => {
      if (Object.values(values).findIndex(value => value === variant.depends_on) > -1) {
        resultValues[variant.id] = variant.variant_value
      } else if (!variant.depends_on) {
        resultValues[variant.id] = variant.variant_value
      }
    });
  }

  return resultValues;
};

export const SkSelectDescriptionsFromApiVariants: (question: ApiQuestionRenderedModel, values: SkFormValues) => SkFieldSelectDescriptions = (question, values) => {
  const resultDescriptions:SkFieldSelectDescriptions = {};

  question.variants.forEach(variant => {
    if ((question.variants_depend_on && values[question.variants_depend_on] === variant.depends_on) || !question.variants_depend_on) {
      resultDescriptions[variant.id] = variant.description;
    }
  });

  return resultDescriptions;
};

const SkFormField:React.FC<{
  q: ApiQuestionRenderedModel;
}> = ({q}) => <SkFormPropsChecker name={"form field renderer"}>
  {(formProps) => {
    const formikProps = useFormikContext<SkFormValues>();
    const {reactiveInitialValues, questions, questionsDisabled} = formProps;
    const [isDisabled, setIsDisabled] = useState(false)

    const isSelectType = q.display_type === "selectfree" || q.display_type === "select";
    const questionsAndIndexDeps = [q.variants, q.variants_depend_on ? formikProps.values[q.variants_depend_on] : ""];
    const selectValues:SkFieldSelectValues = useMemo(() => {
      if (isSelectType) {
        return SkSelectValuesFromApiVariants(q, formikProps.values);
      } else {
        return {};
      }
    }, questionsAndIndexDeps);
    const selectDescriptions:SkFieldSelectDescriptions = useMemo(() => {
      if (isSelectType) {
        return SkSelectDescriptionsFromApiVariants(q, formikProps.values);
      } else {
        return {};
      }
    }, questionsAndIndexDeps);
    const isSelecValuesEmpty: boolean = useMemo(() => Object.entries(selectValues).length <= 0, [selectValues]);

    let fieldProps: SkFieldProps = {
      name: q.id,
      description: q.description,
      label: q.name,
      submittedValue: reactiveInitialValues && reactiveInitialValues[q.id],
      formikProps: formikProps,
      min: q.answers_count_min,
      max: q.answers_count_max,
      disabled: !!questionsDisabled?.find(qid => qid === q.id) || isDisabled,
      key: q.id,
    };
    
    const graduationFieldStore = useGraduationStepField()

    const onChangeCheckboxSame = (e: any) => {
      formikProps.setFieldValue(`${q.settings.same}-same`, [e.target.checked, q.id])
      if (e.target.checked) {
        setIsDisabled(true)
        formikProps.setFieldValue(`${q.id}`, formikProps.values[q.settings.same as string])
      } else {
        setIsDisabled(false)
      }
    }

    // Если чекбокс = true, меняем зависимое disabled поле, если меняется текущее, от которого зависит
    useEffect(() => {
      if (formikProps.values[`${q.id}-same`] && formikProps.values[`${q.id}-same`].length === 2 && formikProps.values[`${q.id}-same`][0] as any === true) {
        formikProps.setFieldValue(formikProps.values[`${q.id}-same`][1] , formikProps.values[q.id])
      }
    }, [formikProps.values[q.id]])
    
    if (q.name === "Research proposal" && graduationFieldStore.graduationStep === "M") {
      return null
    } else {
      if (q.display_type === "upload") {
        return <SkFieldFile {...fieldProps}/>;
      } else if (isSelectType) {
        // автосуджест должен быть select, должен быть отключен флаг disable_autocomplete и число вариантов должно быть больше REACT_APP_SELECT_AUTOSUGGEST_FROM_ITEMS_GTE
        if (isSelecValuesEmpty) {
          // возвращаем ничего, если при отсутствии вариантов нужно скрывать поле
          return null;
        } else {
          if (q.display_type === "select" && !q.disable_autocomplete && q.variants.length > REACT_APP_SELECT_AUTOSUGGEST_FROM_ITEMS_GTE) {
            return <SkFieldAutosuggest
              {...fieldProps}
              question={q}
              questions={questions}
              selectValues={selectValues}
              questionsAndIndexDeps={questionsAndIndexDeps}
            />;
          } else {
            return <SkFieldPicker
              {...fieldProps}
              question={q}
              questions={questions}
              free={q.display_type === "selectfree"}
              selectValues={selectValues}
              selectDescriptions={selectDescriptions}
            />;
          }
        }
      } else if (q.display_type === "phone") {
        return <SkFieldPhone
          {...fieldProps}
        />;
      } else if (q.display_type === "number") {
        return <SkFieldNumber
          {...fieldProps}
        />;
      } else if (q.display_type === "out_of") {
        return <SkFieldOutOf
          {...fieldProps}
        />;
      } else if (q.display_type === "email" || q.display_type === "text" || q.display_type === "shorttext") {
        return <>
          <SkFieldText
            {...fieldProps}
            transform={q.display_type === "email" ? ["lowercase"] : undefined}
            multiline={q.display_type === "text"}
          />
          {q.settings && q.settings.same && 
            <>
              <Form.Group controlId={`${q.settings.same}-same`} style={{display: 'flex'}}>
                <Form.Check className={`${q.settings.same}-same`} value={formikProps.values[`${q.settings.same}-same`]} onChange={onChangeCheckboxSame} />
                <label htmlFor={`${q.settings.same}-same`}>
                  {q.settings.same_text ? q.settings.same_text : 'Совпадает с предыдущим полем'}
                </label>
              </Form.Group>
            </>
          }
        </>
      } else if (q.display_type === "date") {
        return <SkFieldDate
          {...fieldProps}
        />;
      } else if (q.display_type === "recommendation") {
        return <SkFieldRecommendation
          {...fieldProps}
        />;
      } else {
        return <Fragment key={q.id}>
          <Alert variant={"danger"}>An error occured rendering this type of display_type: {q.display_type}, please contact support</Alert>
        </Fragment>;
      }
    } 
  }}
</SkFormPropsChecker>;

const SkFormWithFilteringQuestions:React.FC<{
  className?: string;
  questionBlocks?: SkFormQuestionsBlock[];
  onSubmit: (values: SkFormValues, formikHelpers: FormikHelpers<SkFormValues>) => void;
  onValuesChange?: (values: SkFormValues) => void;
  accepts?: React.ReactNode[];
  beforeSubmitRender?: JSX.Element;
  submitComponent?: React.FC<SkSubmitButtonProps>;
  submitCaption?: string;
  mandatory?: MandatoryMode;
  formName?: string
}> = ({mandatory= "auto", ...props}) => <SkFormPropsChecker name={"form"}>
  {(formProps) => {
    const filteredQuestionsStore = useFilteredQuestions()
    const { filteredQuestions, setFilteredQuestions } = filteredQuestionsStore
    const { formName } = props
    
    const validationSchema = useMemo(() => 
      ValidationSchemaFromQuestions(filteredQuestions[formName as string] 
        ? filteredQuestions[formName as string] 
        : formProps.questions, 
      mandatory), 
    [filteredQuestions[formName as string], mandatory]);

    const SubmitButton = props.submitComponent ? props.submitComponent : SkSubmitButton;
    const showSubmitButton: boolean = useMemo(() => {
      const formQids = (filteredQuestions[formName as string] ? filteredQuestions[formName as string] : formProps.questions).map(q => q.id);
      const intersection = formProps.questionsDisabled?.filter(qid => formQids.includes(qid)) || [];
      return intersection.length !== formQids.length;
    }, [formProps.questions, formProps.questionsDisabled, filteredQuestions[formName as string]]);

    useEffect(() => {
      if (formProps.questions.length > 0) {
        setFilteredQuestions(formProps.questions, formName as string)
      }
    }, [formProps.questions])

    return (
      <>
        <Formik
          className={props.className}
          validationSchema={validationSchema}
          initialValues={formProps.reactiveInitialValues || {}}
          onSubmit={props.onSubmit}
          enableReinitialize={true}
          >
          {(formikProps: FormikProps<SkFormValues>) => {
            useEffect(() => formProps.reactiveInitialValues && formikProps.setValues(formProps.reactiveInitialValues), [formProps.reactiveInitialValues]);
            
            useEffect(() => {
              props.onValuesChange && props.onValuesChange(formikProps.values);
            }, [formikProps.values]);

            // Отслеживаем изменение на каждое изменение в форме
            useEffect(() => {
              // Фильтруем вопросы
              filterQuestions()
            }, [formikProps.values, validationSchema])

            const filterQuestions = () => {
              if (filteredQuestions[formName as string]) {
                setFilteredQuestions(formProps.questions, formName as string)
                const newFilteredQuestions: ApiQuestionRenderedModel[] = []
                filteredQuestions[formName as string].forEach((q) => {
                  const isRenderFieldArray: boolean[] = []
                  const dependencyCondition: 'or' | 'and' = q.dependency.length > 0 ? q.dependency[0].dependency_condition : 'and'
                  q.dependency.forEach((dep) => {
                    if (dep.dependency_resolution === 'visibility') {
                      if (dep.dependency_type === 'answer_neq') {
                        isRenderFieldArray.push(formikProps.values[dep.depended_on_question] !== dep.comp_value_variant)
                      } else {
                        isRenderFieldArray.push(formikProps.values[dep.depended_on_question] === dep.comp_value_variant)
                      }
                    }
                  })
                  if (dependencyCondition === 'and' || dependencyCondition === undefined || dependencyCondition === null) {
                    if (!isRenderFieldArray.includes(false) || isRenderFieldArray.length === 0) {
                      newFilteredQuestions.push(q)
                    }
                  } else {
                    if (isRenderFieldArray.includes(true) || isRenderFieldArray.length === 0) {
                      newFilteredQuestions.push(q)
                    }
                  }
                 
                })                
                
                setFilteredQuestions(newFilteredQuestions, formName as string)
              }
              formikProps.validateForm()
            }

            return <Form onSubmit={formikProps.handleSubmit}>
              {/* Вопросы формы */}
              {props.questionBlocks && props.questionBlocks.length > 0 
                ? props.questionBlocks.map((qb, i) => <React.Fragment key={i}>
                  <h4 className={"sk-divider-header"}>{qb.header}</h4>
                  {filteredQuestions[formName as string]
                    .filter(q => qb.questionsIds
                    .some(qid => qid === q.id))
                    .sort((qa, qb) => qa.sort - qb.sort)
                    .map(q => <SkFormField q={q} key={q.id}/>)
                  }
                </React.Fragment>)
                : filteredQuestions[formName as string] && 
                  filteredQuestions[formName as string]
                  .sort((qa, qb) => qa.sort - qb.sort)
                  .map(q => <SkFormField q={q} key={q.id}/> 
                  )
              }

              {/* Сообщение о статусе */}
              {formikProps.status && (
                <Alert variant={"danger"}>
                  {formikProps.status}
                </Alert>
              )}

              {/* Компонент до отправки формы */}
              {props.beforeSubmitRender ? props.beforeSubmitRender : null}

              {/* Компонент отправки формы */}
              {showSubmitButton && <SubmitButton caption={props.submitCaption || "Save"} formikProps={formikProps} accepts={props.accepts}/>}
            </Form>;
          }}
        </Formik>
      </>
    );
  }}
</SkFormPropsChecker>;

export default SkFormWithFilteringQuestions;
